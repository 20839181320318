<template>
  <div class="bg-rides" id="section-2">
    <div class="container">
      <h3 class="title title-rides">WAHANA</h3>
    </div>
    <swiper
      :slidesPerView="'auto'"
      :spaceBetween="0"
      :navigation="true"
      :modules="modules"
      class="swiper-facilities"
    >
      <swiper-slide v-for="item in data" :key="item.id">
        <div class="slide">
          <div class="grid-board">
            <img class="image" :src="item.image" alt="" />
            <div class="box box-wahana">
              <div class="detail tx-rides">{{ item.name }}</div>
              <p class="tx12 tx-12 desc-rides">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import dataWahana from "../../data/wahana.json";

// import swiper module styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// more module style...

export default {
  name: "wahana-component",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  data() {
    return {
      data: dataWahana.data,
    };
  },
  methods: {
    setData(data) {
      this.data = data;
    },
  },
};
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 100%;
  padding: 0px 24px 0px 120px;
  cursor: grab;
}

.swiper-slide img {
  display: block;
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.swiper-slide {
  width: 20%;
  height: 100%;
  margin-right: 10px !important;
}

@media (max-width: 768px) {
  .swiper {
    padding: 0 !important;
  }
  .swiper-slide {
    width: 90%;
    height: 100%;
    margin-right: 5px !important;
  }

  .rides-slider {
    display: flex;
    margin-left: 0 !important;
  }

  .grid-board .box-wahana {
    position: absolute;
    bottom: 3px;
    left: 5%;
  }
}

.rides-slider {
  display: flex;
  margin-left: 120px;
}
</style>
