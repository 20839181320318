<template>
  <footer class="footer section">
    <div class="container">
      <div class="footer-desk">
        <div class="footer-first">
          <div class="footer-1">
            <img src="Images/Logo/logo_lap.png" alt="" />
          </div>
          <div class="footer-2">
            <ul class="tx-footer">
              <li>
                <a data-link="" data-offset="0" href="#section-1"
                  >TENTANG KAMI</a
                >
              </li>
              <li>
                <a data-link="" data-offset="15" href="#section-6"
                  >HUBUNGI KAMI</a
                >
              </li>
            </ul>
          </div>
          <div class="footer-3">
            <ul class="tx-footer">
              <!-- <li><a href="#">SYARAT & KETENTUAN</a></li> -->
            </ul>
          </div>
          <div class="footer-4">
            <ul class="tx-footer">
              <li>TERHUBUNG DENGAN KAMI</li>
            </ul>
            <ul class="icon-sosmed">
              <li>
                <a
                  href="https://www.facebook.com/LidoAdventurePark"
                  target="_blank"
                  ><img src="Images/Icon/fb.png" alt="icon_fb"
                /></a>
              </li>
              <li>
                <a href="https://twitter.com/lidoadventure" target="_blank"
                  ><img src="Images/Icon/tw.png" alt="icon_fb"
                /></a>
              </li>
              <li>
                <a
                  href="https://instagram.com/LidoAdventurePark"
                  target="_blank"
                  ><img src="Images/Icon/ig.png" alt="icon_fb"
                /></a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCqKwW4ypm9mXUmCF-nuHdxg"
                  target="_blank"
                  ><img src="Images/Icon/yt.png" alt="icon_fb"
                /></a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@lidoadventurepark"
                  target="_blank"
                  ><img src="Images/Icon/tiktok.png" alt="icon_fb"
                /></a>
              </li>
            </ul>
          </div>
          <div class="footer-5">
            <p class="tx12 txn-12 logo-mncland">Developed by</p>
            <a href="https://web.mncland.com/"
              ><img src="Images/Logo/logo_mncland.png" alt="logo"
            /></a>
          </div>
        </div>
        <div class="footer-second">
          <div class="footer-left">
            <div class="copyright">Copyright 2023 - Lido Adventure Park</div>
          </div>
          <div class="footer-right">
            <button
              onclick="topFunction()"
              id="myBtn"
              class="btn-onlinechat title"
              style="display: block"
            >
              <a
                href="https://api.whatsapp.com/send?phone=+6282211353797&amp;text=Halo"
                style="text-decoration: none; color: #fff"
                >Chat With Us</a
              >
            </button>
          </div>
        </div>
      </div>
      <div class="footer-mobile">
        <div class="footer-1">
          <img src="Images/Logo/logo-v.png" alt="" />
        </div>
        <ul class="ft-mobile tx-footer">
          <li>
            <a data-link="" data-offset="110" href="#section-1">TENTANG KAMI</a>
          </li>
          <li>
            <a data-link="" data-offset="110" href="#section-6">HUBUNGI KAMI</a>
          </li>
          <!-- <li><a href="#">SYARAT & KETENTUAN</a></li> -->
        </ul>

        <div class="footer-4">
          <ul class="ft-mobile tx-footer">
            <li>TERHUBUNG DENGAN KAMI</li>
          </ul>
          <ul class="ft-mobile icon-sosmed">
            <li>
              <a
                href="https://www.facebook.com/LidoAdventurePark"
                target="_blank"
                ><img src="Images/Icon/fb.png" alt=""
              /></a>
            </li>
            <li>
              <a href="https://twitter.com/lidoadventure" target="_blank"
                ><img src="Images/Icon/tw.png" alt=""
              /></a>
            </li>
            <li>
              <a href="https://instagram.com/LidoAdventurePark" target="_blank"
                ><img src="Images/Icon/ig.png" alt=""
              /></a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCqKwW4ypm9mXUmCF-nuHdxg"
                target="_blank"
                ><img src="Images/Icon/yt.png" alt=""
              /></a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@lidoadventurepark"
                target="_blank"
                ><img src="Images/Icon/tiktok.png" alt=""
              /></a>
            </li>
          </ul>
        </div>
        <div class="footer-5">
          <p class="tx12 txn-12 logo-mncland">Developed by MNC Land</p>
          <a href="https://web.mncland.com/"
            ><img src="Images/Logo/MNCL new small.png" alt=""
          /></a>
        </div>
        <div class="footer-left">
          <div class="copyright">Copyright 2023 - Lido Adventure Park</div>
        </div>
        <div class="footer-right">
          <button
            onclick="topFunction()"
            id="myBtn"
            class="btn-onlinechat title"
          >
            <a
              href="https://api.whatsapp.com/send?phone=+6282211353797&amp;text=Halo"
              style="text-decoration: none; color: #fff"
              >Chat With Us</a
            >
          </button>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "promo-component",
};
</script>

<style scoped>
.footer .footer-first .footer-5 {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .footer-mobile .footer-left .copyright {
    color: rgb(2, 3, 3) !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    list-style-image: none !important;
    text-align: center;
    margin-top: 20px;
  }

  .footer-mobile .footer-5 {
    display: flex;
    flex-direction: column;
  }
}
</style>
