<template>
  <div class="zone" id="section-3">
    <div>
      <img class="image-zone-desk" src="Images/Zone/zone_desktop.jpg" alt="" />
      <img class="image-zone-mobile" src="Images/Zone/zone_mobile.jpg" alt="" />
    </div>
    <div class="description">
      <div class="space-zone">
        <h3 class="title title-package">ZONA</h3>
        <div class="tx-14-white space-zone">
          Lido Adventure Park terdiri dari beberapa pulau atau zona adventure,
          yakni Pulau Khayangan seluas 1.836 meter persegi, Pulau Bidadari
          seluas 1.064 meter persegi, Pulau Biola seluas 5.681 meter persegi,
          dan terintegrasi dengan Soekarno Park seluas 3.478 meter persegi.
          Keempat zona tersebut menjadi bagian dari Lido Adventure Park yang
          memadukan konsep wisata alam terbuka, wisata kuliner, wisata sejarah,
          dan wisata outbound.
        </div>
        <div class="zone-list">
          <div
            class="zone-item"
            v-for="(data, index) in data_list_zone"
            :key="index"
          >
            <div class="cirle">
              <div class="tx-16">{{ data.name }}</div>
            </div>
            <div>
              <div class="tx-14-avn">{{ data.description }}</div>
              <div class="tx12 tx-12"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataGetListZone from "../../data/getListZone.json";

export default {
  name: "zona-component",
  data() {
    return {
      data_zone: [],
      data_list_zone: dataGetListZone.data,
    };
  },
};
</script>

<style scoped>
.image-zone-mobile {
  object-fit: fill;
}

@media (max-width: 768px) {
  .zone img {
    margin-top: 70px !important;
  }

  @media (max-width: 768px) {
    .zone .description .zone-item {
      margin-right: 0;
      display: grid;
      display: flex;
      gap: 15px;
      align-content: center;
      align-items: center;
      margin-bottom: 15px;
    }
  }

  .description .title-package {
    padding: 0 !important;
  }
}
</style>
