<template>
  <div class="social-media">
    <div class="socialmedia-desk">
      <div class="container">
        <h3 class="tx-socialmedia">GALERI MEDIA SOSIAL</h3>
        <iframe
          src="https://lightwidget.com/widgets/d1b93c4ae20751b498e1af86a96c5d4c.html"
          scrolling="no"
          allowtransparency="true"
          class="lightwidget-widget"
          style="width: 100%; border: 0px; overflow: hidden; height: 370px"
        ></iframe>
      </div>
    </div>
    <div class="socialmedia-mobile">
      <!-- <div class="container"> -->
      <h3 class="tx-socialmedia container">GALERI MEDIA SOSIAL</h3>
      <iframe
        src="https://lightwidget.com/widgets/d1b93c4ae20751b498e1af86a96c5d4c.html"
        scrolling="no"
        allowtransparency="true"
        class="lightwidget-widget"
        style="width: 100%; border: 0px; overflow: hidden; height: 370px"
      ></iframe>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "sosmed-component",
};
</script>

<style scoped></style>
