<template>
  <div class="bg-contact" id="section-6">
    <div class="container">
      <h3 class="title bg-title">HUBUNGI KAMI</h3>
      <div class="location">
        <div class="maps">
          <iframe
            width="100%"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d253580.96851529175!2d106.6675468!3d-6.7451221!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69cdda1bacacdf%3A0xb199cee578898826!2sLido%20Adventure%20Park!5e0!3m2!1sid!2sid!4v1606877102135!5m2!1sid!2sid"
            height="450"
            frameborder="0"
            style="border: 0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
        <div class="detail-loc">
          <h3 class="title tx-titlecontact">Lokasi Kami</h3>
          <p class="title tx-location">Lido Adventure Park</p>
          <p class="detail tx-detailcontact">
            Jl. Raya Bogor - Sukabumi KM 21 Bogor 16110, Jawa Barat - Indonesia
          </p>
          <ul class="list-contact">
            <li class="detail">
              <i class="fas fa-phone"></i>
              <p class="detail tx-contact">0251 8220922</p>
            </li>
            <li class="detail">
              <i class="fab fa-whatsapp"></i>
              <p class="detail tx-contact">0811 1611504</p>
            </li>
            <li class="detail">
              <i class="far fa-envelope"></i>
              <p class="detail tx-contact">lap@lidolakeresort.com</p>
            </li>
          </ul>
          <div class="btnloc-desk">
            <div class="btn-loc">
              <a
                href="https://www.google.com/maps/place/Lido+Adventure+Park/@-6.7457862,106.8073325,17z/data=!3m1!4b1!4m5!3m4!1s0x2e69cd352470b7d5:0xfa32356c052b4915!8m2!3d-6.7457915!4d106.8095212"
                target="_blank"
                ><div class="icon-loc">
                  <div
                    class="bg-icon"
                    style="
                      width: 50px;
                      background-color: #006426;
                      height: 50px;
                      border-radius: 50%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <i
                      class="fas fa-arrow-right"
                      style="
                        width: 30px;
                        height: 30px;
                        border: 1px solid #ffffff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        color: #ffffff;
                      "
                    ></i>
                  </div></div
              ></a>
              <p
                style="
                  margin-bottom: 0;
                  margin-bottom: 0;
                  display: flex;
                  align-items: center;
                  padding-left: 20px;
                "
              >
                Arahkan saya ke lokasi
              </p>
            </div>
          </div>
          <div class="btnloc-mobile">
            <div class="btn-loc">
              <a
                href="https://www.google.com/maps/place/Lido+Adventure+Park/@-6.7457862,106.8073325,17z/data=!3m1!4b1!4m5!3m4!1s0x2e69cd352470b7d5:0xfa32356c052b4915!8m2!3d-6.7457915!4d106.8095212"
                target="_blank"
                ><div class="icon-loc">
                  <div
                    class="bg-icon"
                    style="
                      width: 40px;
                      background-color: #006426;
                      height: 40px;
                      border-radius: 50%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <i
                      class="fas fa-arrow-right"
                      style="
                        width: 23px;
                        height: 23px;
                        border: 1px solid #ffffff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        color: #ffffff;
                        font-size: 12px;
                      "
                    ></i>
                  </div></div
              ></a>
              <p
                style="
                  margin-bottom: 0;
                  margin-bottom: 0;
                  display: flex;
                  align-items: center;
                  padding-left: 5px;
                  padding-right: 20px;
                  color: #ffffff;
                  font-size: 14px;
                "
              >
                Arahkan saya ke lokasi
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "kontak-component",
};
</script>

<style scoped></style>
