<template>
  <div class="banner" id="section-1">
    <div class="swiper-container" id="header-slide">
      <!-- Additional required wrapper -->
      <swiper
        class="swiper"
        :modules="modules"
        :loop="true"
        :space-between="30"
        :centered-slides="true"
        :navigation="true"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
      >
        <swiper-slide>
          <img
            class="imagebanner-desk"
            src="Images/Banner/Red-Suspension-Bridge-1280x853.jpg"
            alt=""
          />
          <img
            class="imagebanner-mobile"
            src="Images/Banner/Red-Suspension-Bridge-1280x853.jpg"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            class="imagebanner-desk"
            src="Images/Banner/HighRope-1280x853.jpg"
            alt=""
          />
          <img
            class="imagebanner-mobile"
            src="Images/Banner/HighRope-1280x853.jpg"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            class="imagebanner-desk"
            src="Images/Banner/Flying-Fox-1280x853.jpg"
            alt=""
          />
          <img
            class="imagebanner-mobile"
            src="Images/Banner/Flying-Fox-1280x853.jpg"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            class="imagebanner-desk"
            src="Images/Banner/SkyBike2-1280x853.jpg"
            alt=""
          />
          <img
            class="imagebanner-mobile"
            src="Images/Banner/SkyBike2-1280x853.jpg"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            class="imagebanner-desk"
            src="Images/Banner/Kayaking-1280x853.jpg"
            alt=""
          />
          <img
            class="imagebanner-mobile"
            src="Images/Banner/Kayaking-1280x853.jpg"
            alt=""
          />
        </swiper-slide>
      </swiper>
    </div>

    <!-- Deskripsi Banner (Di Luar Swiper) -->
    <div class="box">
      <h3 class="title tx-titlebanner">
        Ayo Jelajahi Adventure Park Terbesar di Asia Tenggara!
      </h3>
      <div class="tx-14 detail tx-descbanner">
        Lido Adventure Park adalah kawasan outbound yang berada di Lido, Bogor,
        dan akan menjadi yang terbesar di Asia Tenggara. Kawasan outbound ini
        berada di KEK MNC Lido City, destinasi wisata dan gaya hidup seluas
        3.000 hektar yang dikembangkan oleh MNC Land. Nikmati berbagai kegiatan
        luar ruang yang seru di tepi Danau Lido dengan panorama Gunung Salak,
        Gunung Gede dan Gunung Pangrango
      </div>
    </div>
  </div>
</template>
<script>
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

// import swiper module styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default {
  name: "banner-component",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
  data() {
    return {
      data: [],
    };
  },
};
</script>

<style scoped>
.swiper {
  @include swiper-wrapper();
}

@media (max-width: 768px) {
  .box {
    position: absolute;
    bottom: 16rem;
    z-index: 1;
    left: 0;
    max-width: 375px;
    bottom: 10%;
    left: 10% !important;
  }
}

.slide {
  @include swiper-slide();
}
</style>
