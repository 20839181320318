<template>
  <div class="main-wrapper">
    <Navbar />
    <Banner />
    <Wahana />
    <Zona />
    <Fasilitas />
    <Promo />
  </div>
  <Sosmed />
  <Kontak />
  <Footer />
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Banner from "@/components/Banner.vue";
import Wahana from "@/components/Wahana.vue";
import Zona from "@/components/Zona.vue";
import Fasilitas from "@/components/Fasilitas.vue";
import Promo from "@/components/Promo.vue";
import Sosmed from "@/components/Sosmed.vue";
import Kontak from "@/components/Kontak.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Banner,
    Wahana,
    Zona,
    Fasilitas,
    Promo,
    Sosmed,
    Kontak,
    Footer,
  },
};
</script>
