<template>
  <div class="promo" id="section-5">
    <div class="container">
      <h3 class="tx-socialmedia">PROMO</h3>
      <swiper
        :slidesPerView="1"
        :spaceBetween="30"
        :loop="true"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide>
          <img
            class="image-promo-desk"
            src="Images/Promo/promo.jpg"
            alt="promo"
          />
          <img
            class="image-promo-mobile"
            src="Images/Promo/promo.jpg"
            alt="promo"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

// import swiper module styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// more module style...

export default {
  name: "promo-component",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
};
</script>

<style scoped>
.swiper {
  @include swiper-wrapper();
}

.slide {
  @include swiper-slide();
}

.promo .image-promo-desk {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
</style>
