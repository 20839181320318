<template lang="">
  <header class="nav-desk">
    <div class="container">
      <div class="navbar-desk">
        <div class="favicon">
          <a href="/"
            ><img class="image-logo" src="Images/Logo/logo_lap.png" alt=""
          /></a>
        </div>
        <ul class="tx12 tx-12-nav">
          <li class="border-nav">
            <a data-link data-offset="0" href="#section-1">BERANDA</a>
          </li>
          <li class="border-nav">
            <a data-link data-offset="30" href="#section-2">WAHANA</a>
          </li>
          <li class="border-nav">
            <a data-link data-offset="80" href="#section-3">ZONA</a>
          </li>
          <li class="border-nav">
            <a data-link data-offset="145" href="#section-5">PROMO</a>
          </li>
          <li class="border-nav">
            <a data-link data-offset="15" href="#section-6">HUBUNGI KAMI</a>
          </li>
          <!-- <li>
            <ul class="tx12 language">
              <span style="color: #dddddd">&ensp;|&ensp;</span>
              <li class="border-nav navlink">
                <a
                  href="https://www.lidoadventurepark.com/id/"
                  style="
                    text-decoration: none;
                    font-weight: bold;
                    text-transform: uppercase;
                  "
                  >id</a
                >
              </li>
              <span style="color: #dddddd">&ensp;|&ensp;</span>
              <li class="border-nav navlink">
                <a
                  href="https://www.lidoadventurepark.com/en/"
                  style="text-decoration: none; text-transform: uppercase"
                  >en</a
                >
              </li>
            </ul>
          </li> -->
        </ul>
      </div>
    </div>
  </header>
  <header class="nav-mobile">
    <div class="container">
      <div class="navbar-desk">
        <div class="favicon">
          <a href="/"
            ><img
              class="image-logo"
              src="Images/Logo/logo-v.png"
              width="100%"
              height="54"
              alt=""
          /></a>
        </div>
        <ul class="tx12 tx-12-nav">
          <span
            class="material-symbols-outlined"
            style="cursor: pointer"
            @click="openNavbarMobile"
          >
            menu
          </span>
        </ul>
      </div>
      <div
        class="navbar-mobile"
        v-if="navbar_mobile"
        style="border-top: 1px solid green"
      >
        <li class="border-nav">
          <a data-link data-offset="0" href="#section-1">BERANDA</a>
        </li>
        <li class="border-nav">
          <a data-link data-offset="30" href="#section-2">WAHANA</a>
        </li>
        <li class="border-nav">
          <a data-link data-offset="80" href="#section-3">ZONA</a>
        </li>
        <li class="border-nav">
          <a data-link data-offset="145" href="#section-5">PROMO</a>
        </li>
        <li class="border-nav">
          <a data-link data-offset="15" href="#section-6">HUBUNGI KAMI</a>
        </li>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "Navbar-Component",
  data() {
    return {
      navbar_mobile: false,
    };
  },
  methods: {
    openNavbarMobile() {
      this.navbar_mobile = !this.navbar_mobile;
    },
  },
};
</script>
<style scoped>
.tx12 .material-symbols-outlined {
  margin-top: 10px;
  font-size: 32px !important;
}

@media (max-width: 768px) {
  .navbar-mobile {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: left;
    justify-content: left;
    align-items: start;
    align-content: start;
    list-style-type: none;
  }

  .navbar-desk .image-logo {
    width: 140px;
    height: 54px;
    top: 20px;
    margin-top: 10px;
  }
}
</style>
