<template>
  <div class="amenities">
    <div class="amenities__background">
      <img src="Images/Fasilitas/bg-fasilitas.jpg" alt="" />
    </div>
    <div class="container amenities__banner-lido">
      <h3 class="amenities__title title-padding text-20 title-white">
        TERINTEGRASI DENGAN FASILITAS STAYCATION
      </h3>
      <div class="amenities__images">
        <img src="Images/Fasilitas/fasilitas.jpg" alt="" />
        <div class="amenities__box">
          <h4 class="amenities__sub-title text-20 title-white">
            Lido Lake Resort by MNC Hotel
          </h4>
          <p class="amenities__description text-14 title-white">
            Lengkapi keseruan aktivitas outbound Anda dengan menginap di
            fasilitas resort berbintang di tengah panorama alam Danau Lido.
          </p>
          <div class="amenities__btn">
            <a href="https://www.lidolakeresort.com/">
              <p class="text-16 title-white">Selengkapnya</p>
              <i class="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "fasilitas-component",
};
</script>

<style scoped></style>
